<template>
  <v-container fluid>
    <TableViewComponent
      title="Table types de collaborateurs"
      @addItemEvent="onAddTitle()"
      addItemLabel="ajouter un type de collaborateur"
      itemLabel="type de collaborateurs"
      itemsLabel="types de collaborateurs"
      :items="types"
      :columns="buildColumns()"
      deleteItemLabel="Voulez-vous supprimer ce type de collaborateur ?"
      :loading="loading"
      :rolesForEdition="rolesForEdition"
      :rolesForAddDelete="rolesForAddDelete"
      :rolesForRead="rolesForRead"
    ></TableViewComponent>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import TableViewComponent from "@/components/ui/TableViewComponent.vue";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import UsersTypeService from "@/service/user/user_type_service.js";

import * as exceptions from "@/service/exception_to_message.js";

import { defines as routes } from "@/router/defines.js";

import { RolesApplicationEnum } from "@/service/roles/roles_application.js";


export default {
  name: "TableEmployeeTitles",
  components: { TableViewComponent },
  mixins: [SnackBarMixin],
  data() {
    return {
      /**en cours de chargement */
      loading: false,

      /**le service d'accès au APIs. */
      userTypeService: null,

      /**les élements */
      types: [],
    };
  },
  methods: {
    onAddTitle() {
      this.$router.push(routes.usertypes.add.path);
    },
    async load() {
      try {
        this.loading = true;

        this.types = [];

        let types = await this.userTypeService.getAllUsersType();

        types.forEach((t) => {
          let type = JSON.parse(JSON.stringify(t));

          // Modifie la donnée technique en données visuelle
          type.isPerson = type.person ? "oui" : "non";

          //action de consultation
          type.view = () => {
            this.$router.push(routes.usertypes.view.root + "/" + type.id);
          };

          //action de modification
          type.edit = () => {
            this.$router.push(routes.usertypes.edit.root + "/" + type.id);
          };

          //action de suppression
          type.delete = async () => {
            try {
              await this.userTypeService.delete(type.id);
              this.load();
            } catch (error) {
              console.error(error);
              this.addErrorToSnackbar(
                "suppression du type collaborateur : " +
                  (exceptions.toMessage(error) || "problème technique")
              );
            }
          };
          this.types.push(type);
        });
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    /**Construire les colonnes à afficher dans la vue table */
    buildColumns() {
      let columns = [
        {
          text: "Label",
          align: "start",
          sortable: true,
          value: "label",
          default: true,
        },
        {
          text: "Nom digital",
          align: "start",
          sortable: true,
          value: "digitalName",
          default: true,
        },
        {
          text: "Est un humain",
          align: "start",
          sortable: true,
          value: "isPerson", 
          default: true,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
          default: true,
        },
      ];

      return columns;
    },
  },
  computed: {
    /**Retourne la liste des rôles attendus pour l'édition */
    rolesForEdition() {
      return [RolesApplicationEnum.EditUser];
    },
    /** Retourne la liste des rôles attendus pour l'ajout/suppression */
    rolesForAddDelete() {
      return [ RolesApplicationEnum.EditUser, ];
    },
    /**Retourne la liste des rôles attendus pour la lecture */
    rolesForRead() {
      return [RolesApplicationEnum.ReadUser];
    },
  },
  mounted() {
    this.userTypeService = new UsersTypeService(this.$api);

    this.load();
  },
};
</script>

<style>
</style>